import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import red from '@material-ui/core/colors/red';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import { FileCopy } from '@material-ui/icons';
import WebitelButton from '../webitel_button/WebitelButton';
import { calculateAge, formatCurrency, formatPhoneNumber } from '../../utils/formatter';
import Call from '../call/Call';
import TabPanel from '../hoc/TabPanel';
import DocumentVerification from '../document_verification/DocumentVerification';
import BlackList from '../black_list/BlackList';
import BlackListMatch from '../black_list/BlackListMatch';
import copyToClipboard from '../../utils/copyToClipboard';
import ImageZoom, { findImage } from './../pages/user/ImageZoom';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  redChip: {
    color: 'white',
    backgroundColor: red[500],
  },
  tagsGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  table: {
    tableLayout: 'fixed',
    '& .MuiTableCell-root': {
      wordWrap: 'break-word',
    },
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '6px 24px 6px 16px',
    width: 'fit-content',
  },
  breakable: {
    wordBreak: 'break-all',
  },
  tabPanel: {
    paddingTop: '10px',
    paddingBottom: '16px',
  },
}));

const rowData = (name, value, style) => ({ name, value, style });

const TabbedInfoCard = ({ user_id, inactive = false }) => {
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const copyText = async text => {
    try {
      await copyToClipboard(text);
      notify('Text copied to clipboard!', { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };
  useEffect(() => {
    setLoading(true);
    dataProvider.query(`users/${user_id}`, {}).then(({ data }) => {
      setRecord(data);
      setLoading(false);
    });
  }, [dataProvider, user_id]);

  if (loading) {
    return <CircularProgress />;
  }

  const profileRows = [
    rowData(
      'ID',
      <Box className={classes.flexRow}>
        <WebitelButton userId={record.id} />
        <Link href={'#users/' + record.id}>{record.id}</Link>
      </Box>,
    ),
    rowData('First name', record.first_name), // NOTE: to fix accordingly to Peru name structure
    rowData('Last name', record.last_name),
    rowData('Father surname', record.father_surname),
    rowData('Mother surname', record.mother_surname),
    rowData(
      'Phone number',
      <Call inactive={inactive} userId={record.id} userPhone={record.phone_number} edge="end">
        {formatPhoneNumber(record.phone_number)}
      </Call>,
      { display: 'flex' },
    ),
    rowData('DNI number', record.dni),
    rowData('DNI expires at', record.expired_at),
    rowData('Gender', record.gender),
    rowData('Birthday', `${record.birthday}  (${calculateAge(record.birthday)} y.o.)`),
    rowData('Birthplace', record.birth_place),
    rowData('Email', record.email),
    rowData('Education', record.education),
    rowData('Monthly income', record.monthly_income && formatCurrency(record.monthly_income)),
    rowData('Monthly exprenses', record.monthly_expense && formatCurrency(record.monthly_expense)),
    rowData('Marital status', record.marital_status),
    rowData('In blacklist', record.is_in_black_list ? 'true' : 'false'),
    rowData(
      'User tags',
      record?.tags && record.tags.length ? (
        <Box className={classes.tagsGrid}>
          {record.tags.map(tag => {
            if (tag.name.includes('not disturb')) {
              return (
                <Chip
                  className={classes.redChip}
                  icon={<ErrorOutlineIcon style={{ color: 'white' }} />}
                  key={tag.id}
                  size="small"
                  label={tag.name}
                />
              );
            } else {
              return <Chip key={tag.id} size="small" label={tag.name} color="primary" />;
            }
          })}
        </Box>
      ) : null,
    ),
  ];

  const residenceRows = [
    rowData('Department', record.residence_department),
    rowData('Province', record.residence_province),
    rowData('District', record.residence_district),
    rowData('Address', record.residence_address),
  ];

  const employmentRows = [
    rowData('Employment type', record.employment_type),
    rowData('Employment industry', record.employment_industry),
  ];

  const bankRows = [
    rowData('Bank name', record.bank_name),
    rowData('Bank account number', record.bank_account_number),
    rowData('Bank account type', record.bank_account_type),
  ];

  return (
    <>
      {!record.is_in_black_list && record.is_find_in_black_list && (
        <BlackListMatch findInBlackList={record.find_in_black_list} username={record.username} />
      )}
      {record.is_in_black_list && (
        <BlackList
          createdBy={record.black_list.createdBy}
          createdAt={record.black_list.createdAt}
          comment={record.black_list.comment}
          reason={record.black_list.reason}
        />
      )}
      {record.state === 'blocked' && (
        <MuiAlert elevation={6} variant="filled" severity="error" className={classes.mb3}>
          User is blocked!
        </MuiAlert>
      )}
      <Paper>
        <TabPanel
          labels={['Profile', 'Residence & Employment', 'Bank', 'Photo', 'Document verification']}
          className="test">
          <Box className={classes.tabPanel}>
            <Table className={classes.table} size="small">
              <TableBody>
                {profileRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right" style={row.style}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box className={classes.flexColumn}>
              <Typography variant="body2" gutterBottom={false}>
                User target URL
              </Typography>
              <Box className={classes.flexRow}>
                {record.target_url ? (
                  <>
                    <Typography className={classes.breakable} variant="body2" gutterBottom={false}>
                      {record.target_url}
                    </Typography>
                    <IconButton onClick={() => copyText(record.target_url)}>
                      <FileCopy />
                    </IconButton>
                  </>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box className={classes.tabPanel}>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Residence
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {residenceRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Employment
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {employmentRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right" style={row.style}>
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box className={classes.tabPanel}>
            <Typography variant="h6" gutterBottom={false} className={classes.paper}>
              Bank
            </Typography>
            <Divider />
            <Table className={classes.table} size="small">
              <TableBody>
                {bankRows.map(row => (
                  <TableRow key={row.name}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
          <Box className={classes.tabPanel} px={2}>
            <Grid container spacing={2}>
              {findImage(record.uploaded_files, 'selfie') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Selfie
                  </Typography>
                  {record.similarity_card_and_selfie !== null ? (
                    <Typography variant="body1">{`Similarity ${record.similarity_card_and_selfie}%`}</Typography>
                  ) : (
                    findImage(record.uploaded_files, 'id_card') &&
                    findImage(record.uploaded_files, 'selfie') && (
                      <Typography variant="caption" color="error">
                        No face is recognized on the DNI card
                      </Typography>
                    )
                  )}
                  <Divider />
                  <ImageZoom isExist userId={record.id} label="selfie" />
                </Grid>
              )}
              {findImage(record.uploaded_files, 'id_card') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    DNI card front
                  </Typography>
                  <Divider />
                  <ImageZoom isExist userId={record.id} label="id_card" />
                </Grid>
              )}
              {findImage(record.uploaded_files, 'id_card_back_side') && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    DNI card back
                  </Typography>
                  <Divider />
                  <ImageZoom isExist userId={record.id} label="id_card_back_side" />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box className={classes.tabPanel} px={2}>
            <DocumentVerification userId={user_id} />
          </Box>
        </TabPanel>
      </Paper>
    </>
  );
};

TabbedInfoCard.propTypes = {
  user_id: PropTypes.number,
  inactive: PropTypes.bool,
};

export default TabbedInfoCard;
