import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { debounce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { green, orange, red, grey } from '@material-ui/core/colors';
import Toolbar from '@material-ui/core/Toolbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { getUserId } from '../../../utils/authentication';

const useStyles = makeStyles(theme => ({
  formControl500: {
    margin: theme.spacing(1),
    width: 400,
  },
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: 'none',
    borderRight: 'none',
    marginTop: theme.spacing(2),
  },
  fontBold: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
    },
  },
  borderBottomNone: {
    borderBottom: 'none',
  },
  orange: {
    backgroundColor: orange[300],
  },
  green: {
    backgroundColor: green[300],
  },
  red: {
    backgroundColor: red[300],
  },
  grey: {
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
    },
    backgroundColor: grey[200],
  },
}));

const calculatePercentage = data => {
  const totalObject = {
    date: 'total',
    Income: { inbox: 0, read: 0 },
    'Promise date': { inbox: 0, read: 0 },
    'Communication reminder': { inbox: 0, read: 0 },
    'Login to personal account': { inbox: 0, read: 0 },
    'Login by short link': { inbox: 0, read: 0 },
  };

  data.forEach(item => {
    Object.keys(totalObject).forEach(key => {
      if (key !== 'date') {
        totalObject[key].inbox += item[key].inbox;
        totalObject[key].read += item[key].read;
      }
    });
  });

  const updatedData = [...data, totalObject];

  updatedData.forEach(item => {
    Object.keys(item).forEach(key => {
      if (key !== 'date') {
        const inbox = item[key].inbox;
        const read = item[key].read;
        item[key]['% read'] = inbox > 0 ? ((read / inbox) * 100).toFixed(0) + '%' : '0%';
      }
    });
  });

  return updatedData;
};

const mapDataToObjects = arr => {
  return arr.map(item => {
    const data = [];

    Object.keys(item).forEach(key => {
      if (key !== 'date') {
        const { inbox, read, '% read': percentRead } = item[key];
        data.push(inbox, read, percentRead); // Push values directly into the array
      }
    });

    return { name: item.date, data };
  });
};

const getRowName = name => {
  switch (name) {
    case 'current_date':
      return 'Today';
    case '1_date_before':
      return '1 day before';
    case '2_date_before':
      return '2 days before';
    case '3_date_before':
      return '3+ days before';
    case 'total':
      return 'Total';
    default:
      return '';
  }
};

const CollectionAlertsReport = ({ collectionGroups, admins }) => {
  const userId = getUserId();

  const [collectionGroupsSelected, setCollectionGroupsSelected] = React.useState([]);
  const [collectors, setCollectors] = React.useState([getUserId()]);
  const [statistics, setStatistics] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const fetchOptions = () => {
    const groupsQuery =
      collectionGroupsSelected.length > 0
        ? collectionGroupsSelected.map(g => `collection_group_list[]=${g}`).join('&')
        : null;
    const collectorsQuery = collectors.length > 0 ? collectors.map(c => `collector_id_list[]=${c}`).join('&') : null;
    const endpoint = 'admin_notifications/statistic';
    const query = collectorsQuery ? `${endpoint}?${collectorsQuery}` : `${endpoint}?${groupsQuery}`;
    setLoading(true);
    dataProvider
      .query(query, { method: 'GET' })
      .then(res => setStatistics(calculatePercentage(res.data)))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  };

  const debouncedFetchData = React.useMemo(
    () => debounce(fetchOptions, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collectors, collectionGroupsSelected],
  );

  React.useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const getCellColorByPercentage = percentageStr => {
    const percentage = parseInt(percentageStr, 10);

    switch (true) {
      case percentage >= 0 && percentage <= 39:
        return classes.red;
      case percentage >= 40 && percentage <= 69:
        return classes.orange;
      case percentage >= 70 && percentage <= 100:
        return classes.green;
      default:
        return null;
    }
  };

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(statistics)) return null;

  return (
    <>
      {admins.length > 0 && collectionGroups.length > 0 && (
        <Toolbar variant="dense" disableGutters>
          {collectionGroups.length > 0 && (
            <FormControl className={classes.formControl500} variant="filled">
              <InputLabel>Collection groups</InputLabel>
              <Select
                multiple
                value={collectionGroupsSelected}
                onChange={e => {
                  setCollectionGroupsSelected(e.target.value);
                  setCollectors(e.target.value.length ? [] : [getUserId()]);
                }}
                renderValue={selected =>
                  selected.length
                    ? selected.map(id => collectionGroups.find(group => group.id === id)?.code).join(', ')
                    : null
                }>
                {collectionGroups.map(group => (
                  <MenuItem key={group.id} value={group.id}>
                    {group.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {admins.length > 0 && (
            <FormControl className={classes.formControl500} variant="filled">
              <InputLabel>Collectors</InputLabel>
              <Select
                multiple
                value={collectors}
                onChange={e => {
                  setCollectors(e.target.value);
                  setCollectionGroupsSelected(e.target.value.length ? [] : [1]);
                }}
                renderValue={selected =>
                  selected.length
                    ? selected.map(id => admins.find(admin => admin.id === id)?.username).join(', ')
                    : null
                }>
                {admins.map(admin => (
                  <MenuItem key={admin.id} value={admin.id}>
                    {admin.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button
            startIcon={<ClearIcon />}
            variant="text"
            color="primary"
            onClick={() => {
              setCollectors([userId]);
              setCollectionGroupsSelected([]);
            }}>
            Reset filters
          </Button>
        </Toolbar>
      )}
      {loading ? (
        <Box mt={5} display="flex" justifyContent="center" alignItems="center" sx={{ gap: '8px' }}>
          <CircularProgress size={20} />
          <Typography variant="body2">Loading report ...</Typography>
        </Box>
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow className={classes.fontBold}>
                <TableCell className={classes.borderBottomNone}></TableCell>
                <TableCell colSpan={3} align="center">
                  Income
                </TableCell>
                <TableCell colSpan={3} align="center">
                  Promise date
                </TableCell>
                <TableCell colSpan={3} align="center">
                  Communication reminder
                </TableCell>
                <TableCell colSpan={3} align="center">
                  Login to personal account
                </TableCell>
                <TableCell colSpan={3} align="center">
                  Login by short link
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Inbox</TableCell>
                <TableCell align="center">Read</TableCell>
                <TableCell align="center">% Read</TableCell>
                <TableCell align="center">Inbox</TableCell>
                <TableCell align="center">Read</TableCell>
                <TableCell align="center">% Read</TableCell>
                <TableCell align="center">Inbox</TableCell>
                <TableCell align="center">Read</TableCell>
                <TableCell align="center">% Read</TableCell>
                <TableCell align="center">Inbox</TableCell>
                <TableCell align="center">Read</TableCell>
                <TableCell align="center">% Read</TableCell>
                <TableCell align="center">Inbox</TableCell>
                <TableCell align="center">Read</TableCell>
                <TableCell align="center">% Read</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mapDataToObjects(statistics).map(row => (
                <TableRow key={row.name} className={row.name === 'total' ? classes.grey : null}>
                  <TableCell component="th" scope="row">
                    {getRowName(row.name)}
                  </TableCell>
                  {row.data.map((item, index) => (
                    <TableCell
                      className={
                        typeof item === 'string' && row.name !== 'total' ? getCellColorByPercentage(item) : null
                      }
                      key={index}
                      align="right">
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

CollectionAlertsReport.propTypes = {
  admins: PropTypes.array,
  collectionGroups: PropTypes.array,
};

CollectionAlertsReport.defaultProps = {
  admins: [],
  collectionGroups: [],
};

export default CollectionAlertsReport;
