import React, { useEffect, useState } from 'react';
import { refreshView } from 'ra-core';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import RefreshButton from '../refresh/RefreshButton';
import { TIMEZONE_OFFSET } from '../../utils/dictionary';
import getAdminTimezone from '../../utils/getAdminTimezone';
import { timezoneOffsetLabel } from '../../utils/adapter';
import Watermark from '../watermark/Watermark';

import AppBar from './AppBar';
import AdminNotifications from './AdminNotifications';
import { BaseUrlHandler } from './BaseUrlHandler';
import { isDevelopment } from '../../utils/env';

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  color: {
    color: 'white',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  spacer: {
    flex: 1,
  },
}));

export default props => {
  const getUserName = () => {
    const userJson = localStorage.getItem('admin_fields');
    if (!userJson) {
      return 'Unknown';
    }
    const userObject = JSON.parse(userJson);
    return (userObject || {}).username || 'Unknown';
  };

  const dispatch = useDispatch();
  const classes = useStyles();
  const [user, setUser] = useState(getUserName);
  const [timezone, setTimezone] = useState(() => getAdminTimezone(false));

  useEffect(() => {
    const onChangeTimezone = () => {
      setTimezone(getAdminTimezone(false));
    };

    document.addEventListener('change-timezone', onChangeTimezone);

    return () => {
      document.removeEventListener('change-timezone', onChangeTimezone);
    };
  }, []);

  useEffect(() => {
    if (timezone !== getAdminTimezone(false)) {
      localStorage.setItem('admin_timezone', timezone);
      document.dispatchEvent(new Event('change-timezone'));

      dispatch(refreshView());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone]);

  return (
    <AppBar {...props} className={classes.refresh} header={process.env.REACT_APP_PROJECT}>
      <Watermark top={0} left="380px" />
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
      <Typography component="div" className={classes.marginRight}>
        Timezone: &nbsp;
        <Select value={timezone} onChange={event => setTimezone(event.target.value)} style={{ color: 'white' }}>
          {Object.entries(TIMEZONE_OFFSET).map(([tz, offsetFunc]) => (
            <MenuItem key={tz} value={tz}>
              {tz}
              {tz !== 'UTC' ? ` (UTC${timezoneOffsetLabel(offsetFunc())})` : ''}
            </MenuItem>
          ))}
        </Select>
      </Typography>
      <AdminNotifications />
      {isDevelopment && <BaseUrlHandler />}
      <Typography className={classes.marginRight}>Logged in as: {user}</Typography>
      <RefreshButton getUserName={getUserName} setUser={setUser} className={classes.color} />
    </AppBar>
  );
};
