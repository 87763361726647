import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { phoneNumber as phoneNumberValidator } from '../../utils/adapter';
import { PHONE_COUNTRY_CODE } from '../../constants';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  alignEnd: {
    textAlign: 'end',
  },
}));

const messageValidator = (value, min, max) => {
  if (value === undefined) return undefined;
  const regex = new RegExp(`^[\\s\\S]{${min},${max}}$`);
  if (!regex.test(value)) {
    return `Min symbols is ${min}, max ${max}`;
  }
  return undefined;
};

const AddAccountMessageDialog = ({ userId, onClose, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(null);
  const [telegramLink, setTelegramLink] = useState(null);
  const [whatsAppLink, setWhatsAppLink] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberValidator.formatter());
  const [text, setText] = useState(null);
  const [closeable, setCloseable] = useState(true);
  const [errors, setErrors] = useState({});

  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const invalidForm = errors.text || errors.type || errors.phone;

  const validateForm = () => {
    const formErrors = {};

    if (!type) {
      formErrors.type = 'Message type is required';
    }

    if (!text) {
      formErrors.text = 'Message text is required';
    }

    const isValid = Object.keys(formErrors).length === 0;

    setErrors(formErrors);

    return isValid;
  };

  const validateField = useCallback((type, value) => {
    if (type === 'phone' && value.length > 3 && !phoneNumberValidator.validator(phoneNumberValidator.parser(value))) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: 'Invalid phone number',
        };
      });
    } else if (type === 'text' && messageValidator(value, 2, 1000) !== undefined) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: messageValidator(value, 2, 1000),
        };
      });
    } else if (type === 'type' && value === undefined) {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: 'Invalid type',
        };
      });
    } else {
      setErrors(prevState => {
        return {
          ...prevState,
          [type]: undefined,
        };
      });
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true);

      const data = {
        user_id: userId,
        type,
        text,
        telegram_link_url: telegramLink,
        whatsapp_link_url: whatsAppLink,
        phone_number: phoneNumber === PHONE_COUNTRY_CODE ? null : phoneNumber,
        can_be_closed: closeable,
      };

      dataProvider
        .create('user_notifications', { data })
        .then(() => {
          setLoading(false);
          onSubmit();
        })
        .catch(error => {
          setLoading(false);
          notify(`Error: ${error.message}`, 'error');
        });
    }
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">Create new message</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, fill the following form</DialogContentText>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="label-select-type" required>
                Message type
              </InputLabel>
              <Select
                labelId="label-select-type"
                value={type || ''}
                onChange={e => {
                  setType(e.target.value);
                  validateField('type', e.target.value);
                }}
                error={errors.type !== undefined}>
                <MenuItem value="success">Success</MenuItem>
                <MenuItem value="danger">Danger</MenuItem>
                <MenuItem value="warning">Warning</MenuItem>
                <MenuItem value="info">Info</MenuItem>
              </Select>
              {errors.type !== undefined && <FormHelperText error>{errors.type}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} alignContent="flex-end" className={classes.alignEnd}>
            <FormControlLabel
              control={<Switch checked={closeable} onChange={e => setCloseable(e.target.checked)} color="primary" />}
              label="Can be closed"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              fullWidth
              label="Telegram link"
              value={telegramLink || ''}
              onChange={e => setTelegramLink(e.target.value)}
              error={errors.telegram_link_url !== undefined}
              helperText={errors.telegram_link_url}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              fullWidth
              label="WhatsApp link"
              value={whatsAppLink || ''}
              onChange={e => setWhatsAppLink(e.target.value)}
              error={errors.whatsAppLink !== undefined}
              helperText={errors.whatsAppLink}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              fullWidth
              label="Phone number"
              value={phoneNumber}
              onChange={e =>
                setPhoneNumber(phoneNumberValidator.formatter(phoneNumberValidator.parser(e.target.value), false))
              }
              autoComplete={false}
              onBlur={e => e.target.value && validateField('phone', e.target.value)}
              error={errors.phone !== undefined}
              helperText={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              multiline={true}
              minRows="4"
              maxRows="9"
              fullWidth={true}
              label="Message"
              required
              value={text || ''}
              onChange={e => setText(e.target.value)}
              onBlur={e => validateField('text', e.target.value)}
              error={errors.text !== undefined}
              helperText={errors.text}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={20} />}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={invalidForm}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddAccountMessageDialog.propTypes = {
  userId: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddAccountMessageDialog;
