import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Box, Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

const UserProfileCard = forwardRef(({ title, readOnlyContent, editContent }, ref) => {
  const [editMode, setEditMode] = useState(false);

  useImperativeHandle(ref, () => ({
    resetEditMode: () => setEditMode(false),
  }));

  return (
    <Grid item xs={12} style={{ marginTop: '12px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" style={{ color: '#6c6565' }}>
          {title}
        </Typography>
        {!readOnlyContent || !editContent ? null : (
          <Tooltip title={editMode ? 'Close without saving' : 'Edit info'} arrow>
            <IconButton onClick={() => setEditMode(!editMode)}>{editMode ? <CloseIcon /> : <EditIcon />}</IconButton>
          </Tooltip>
        )}
      </Box>
      {!editMode ? (
        <Box>{readOnlyContent}</Box>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
          {editContent}
        </Box>
      )}
    </Grid>
  );
});

UserProfileCard.propTypes = {
  title: PropTypes.string,
  readOnlyContent: PropTypes.node,
  editContent: PropTypes.node,
};

export default UserProfileCard;
