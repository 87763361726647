import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  ChipField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  TextInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Pagination from '../../Pagination';
import { marginZeroStyles } from '../../../constants';
import { formatCurrency, formatDatetime } from '../../../utils/formatter';
import { CAMPAIGN_TYPE_CHOICES } from '../../../utils/dictionary';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Campaign name" source="name" />
      <SelectInput label="Campaign type" source="campaign_type" choices={CAMPAIGN_TYPE_CHOICES} />
      <BooleanInput source="is_enabled" label="Active campaign" defaultValue={true} />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const DiscountCampaignList = props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <ChipField source="campaign_type" label="Type of campaign" size="small" />
      <FunctionField
        label="Discount"
        render={record => {
          if (record.discount_type === 'amount') {
            return (
              <Typography variant="body2">
                <b>Discount PEN: </b>
                {formatCurrency(record.discount_total_amount)}
              </Typography>
            );
          } else if (record.discount_type === 'total_percent') {
            return (
              <Typography variant="body2">
                <b>Discount %: </b>
                {record.discount_total_percent}
              </Typography>
            );
          } else if (record.discount_type === 'compound_percent') {
            return (
              <>
                <Typography variant="body2">
                  <b>Discount for Principal %: </b>
                  {record.discount_principal_percent}
                </Typography>
                <Typography variant="body2">
                  <b>Discount for Interest %: </b>
                  {record.discount_interest_percent}
                </Typography>
                <Typography variant="body2">
                  <b>Discount for Past due interest %: </b>
                  {record.discount_past_due_interest_percent}
                </Typography>
                <Typography variant="body2">
                  <b>Discount for commission %: </b>
                  {record.discount_commission_percent}
                </Typography>
              </>
            );
          } else {
            return null;
          }
        }}
      />
      <FunctionField
        label="Valid from"
        source="valid_from"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <FunctionField
        label="Valid till"
        source="valid_to"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <FunctionField
        label="Active"
        render={record => {
          if (record.is_enabled) {
            return <Typography variant="body2">Active</Typography>;
          } else {
            return <Typography variant="body2">Deactivated</Typography>;
          }
        }}
      />
      <TextField source="created_admin" label="Created by" />
      <FunctionField
        label="Created at"
        source="created_at"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
      <TextField source="updated_admin" label="Last updated by" />
      <FunctionField
        label="Last updated at"
        source="updated_at"
        render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
      />
    </Datagrid>
  </List>
);

export default DiscountCampaignList;
