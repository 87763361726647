import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDatetime } from '../../utils/formatter';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxWidth: '300px',
  },
  inactiveOtp: {
    opacity: 0.5,
  },
  usedOtp: {
    fontWeight: 600,
    opacity: 1,
  },
}));

const OtpCodesTable = ({ ownerId = null, applicationId = null, loanId = null, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [otps, setOtps] = useState();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    dataProvider
      .getList('otp', {
        filter: {
          ...(ownerId ? { owner_id: ownerId } : {}),
          ...{ owner_type: 'user' },
        },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setOtps(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, ownerId, applicationId, loanId, refreshedAt, page, perPage]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(otps)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Subject type</TableCell>
            <TableCell>Subject id</TableCell>
            <TableCell>Code</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Expire at</TableCell>
            <TableCell>Confirmed at</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otps.map(row => {
            const cellClasses = classNames({
              [classes.inactiveOtp]: !row.active,
            });
            return (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell className={cellClasses}>{row.subject_type}</TableCell>
                <TableCell>{row.subject_id}</TableCell>
                <TableCell className={cellClasses}>{row.code}</TableCell>
                <TableCell className={cellClasses}>{formatDatetime(row.created_at)}</TableCell>
                <TableCell className={cellClasses}>{formatDatetime(row.expire_at)}</TableCell>
                <TableCell className={cellClasses}>{formatDatetime(row.confirmed_at)}</TableCell>
                <TableCell className={cellClasses}>{row.active ? 'active' : 'expired'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

OtpCodesTable.propTypes = {
  ownerId: PropTypes.number,
  applicationId: PropTypes.number,
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onTransition: PropTypes.func,
  inactive: PropTypes.bool,
};

export default OtpCodesTable;
