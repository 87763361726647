import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  FunctionField,
  Filter,
  NumberInput,
  SelectInput,
  TextInput,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import { getRequestDuration } from '../../../utils/getRequestDuration';
import Pagination from '../../Pagination';
import DateFilterInput from '../../filter/DateFilterInput';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import { formatDatetime } from '../../../utils/formatter';
import { AVENTUS_DECISION_ENGINE_STATUSES as ade_statuses } from '../../../utils/dictionary';
import QuickFilterInput from '../../filter/QuickFilterInput';
import { timezone } from '../../../utils/adapter';
import ApplicationColorLegend from '../../application-color-legend/ApplicationColorLegend';
import { marginZeroStyles, applicationStatusChoices } from '../../../constants';

import { verifierRow } from './utils';
import ApplicationsBulkActionButtons from './components/ApplicationsBulkActionButtons';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="Contract number" source="contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <TextInput label="Verifier" source="verifier->username" />
      {props.withStatus && <SelectInput label="Status" source="state" choices={applicationStatusChoices} />}
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <SelectInput
        label="Request type"
        source="is_repeat"
        choices={[
          { id: 'false', name: 'First' },
          { id: 'true', name: 'Repeated' },
        ]}
      />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{
          resource: 'affiliates',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <QuickFilterInput
        source="postponed_until|after"
        label="Postponed all"
        defaultValue={timezone.shift(new Date())}
      />
      <QuickFilterInput
        source="postponed_until|before"
        label="Postponed in work"
        defaultValue={timezone.shift(new Date())}
      />
    </Filter>
  );
};

ListFilter.propTypes = {
  withStatus: PropTypes.bool,
};

const ListActions = ({ className, filters, withLegend = true, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {withLegend && <ApplicationColorLegend />}
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
  withLegend: PropTypes.bool,
};

export const VerificationControlApplicationList = props => {
  const isRowSelectable = record =>
    record.state === 'pending' ||
    (record.state === 'processing' && ['manual', null].includes(record.decision_engine_id));

  return (
    <List
      pagination={<Pagination />}
      // bulkActionButtons={<ApplicationsBulkActionButtons />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter withStatus={false} />}
      filter={{ state: 'verification_control' }}
      actions={<ListActions withLegend={false} />}
      {...props}>
      <Datagrid rowClick="show" isRowSelectable={isRowSelectable}>
        <TextField source="id" />
        <FunctionField
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => {
            return (
              <Link
                onClick={e => e.stopPropagation()}
                href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>
            );
          }}
        />
        <ChipField size="small" source="product_name" />
        <NumberField source="requested_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="max_approved_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="requested_tenor" />
        <FunctionField
          render={({ decision_engine_id, state, verifier_username }) =>
            verifierRow(decision_engine_id, state, verifier_username)
          }
          label="Verifier"
        />
        <FunctionField
          render={({ decision_engine_id, state, checking_verifier_username }) =>
            verifierRow(decision_engine_id, state, checking_verifier_username)
          }
          label="Checking verifier"
        />
        <FunctionField label="Request type" render={({ is_repeat }) => (is_repeat ? 'Repeat' : 'First')} />
        <NumberField label="Request number" source="request_number" />
        <NumberField label="Loan number" source="potential_loan_number" />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Affiliate"
          source="affiliate"
          render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
        />
        <FunctionField
          label="ADE status"
          source="aventus_decision_engine_status"
          render={(record, key) =>
            record[key] ? (
              <Chip size="small" label={(ade_statuses.find(item => item.id === record[key]) || { name: null }).name} />
            ) : null
          }
        />
        <FunctionField
          label="New tab"
          render={record => (
            <Link href={`#applications/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
        <FunctionField
          label="Verification"
          render={record => (
            <Link
              href={`#applications_verification/${record.id}/show`}
              underline="none"
              target="_blank"
              rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

const ApplicationList = props => {
  const isRowSelectable = record =>
    record.state === 'pending' ||
    (record.state === 'processing' && ['manual', null].includes(record.decision_engine_id));

  const loanRowStyle = record => ({
    backgroundColor: getRequestDuration(record),
  });

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter withStatus />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowStyle={loanRowStyle} rowClick="show" isRowSelectable={isRowSelectable}>
        <TextField source="id" />
        <FunctionField
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => {
            return (
              <Link
                onClick={e => e.stopPropagation()}
                href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>
            );
          }}
        />
        <ChipField size="small" source="product_name" />
        <NumberField source="requested_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="max_approved_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="requested_tenor" />
        <ChipField size="small" source="state" />
        <FunctionField
          render={({ decision_engine_id, state, verifier_username }) =>
            verifierRow(decision_engine_id, state, verifier_username)
          }
          label="Verifier"
        />
        <FunctionField label="Request type" render={({ is_repeat }) => (is_repeat ? 'Repeat' : 'First')} />
        <NumberField label="Request number" source="request_number" />
        <NumberField label="Loan number" source="potential_loan_number" />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Affiliate"
          source="affiliate"
          render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
        />
        <FunctionField
          label="ADE status"
          source="aventus_decision_engine_status"
          render={(record, key) =>
            record[key] ? (
              <Chip size="small" label={(ade_statuses.find(item => item.id === record[key]) || { name: null }).name} />
            ) : null
          }
        />
        <FunctionField
          label="New tab"
          render={record => (
            <Link href={`#applications/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
        <FunctionField
          label="Verification"
          render={record => (
            <Link
              href={`#applications_verification/${record.id}/show`}
              underline="none"
              target="_blank"
              rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

export default ApplicationList;
