import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  NumberInput,
  useDataProvider,
  minValue,
  maxValue,
  number,
  useNotify,
} from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Autocomplete from '../../autocomplete/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import CheckFileResultsDialog from './CheckFileResultsDialog';
import {
  TRANSMITTER,
  CAMPAIGN_TYPE_CHOICES,
  DISCOUNT_TYPE_CHOICES,
  PAYMENT_LIMIT_CHOICES,
  AUDIENCE_TYPE_CHOICES,
} from '../../../utils/dictionary';
import { integerValidator, isValidDateString, selectedValidator, shortTimeValidator } from '../../../utils/validator';
import { convertToISOString, getToday } from '../../../utils/formatter';
import { DAYS_MODE_DOW, DAYS_MODE_DOM, WEEK_DAYS, MONTH_DAYS, DAYS_MODE_ED } from '../../../constants';
import { formValidator } from './validators';
import { getUserAttachments } from '../../../utils/getUserImages';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
  hidden: {
    display: 'none',
  },
  removeIcon: {
    padding: 0,
    margin: 0,
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
}));

const FormWrapper = ({ save, campaignType, ...props }) => {
  const [daysMode, setDaysMode] = useState(DAYS_MODE_DOW);
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [isCmSelected, setIsCmSelected] = useState(false);
  const [discountTypeSelected, setDiscountTypeSelected] = useState('');
  const [audienceTypeSelected, setAudienceTypeSelected] = useState('');
  const [sendNotification, setSendNotification] = useState(false);
  const [errors, setErrors] = useState({});
  const [cmProfilesList, setCmProfilesList] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [isCheckFileResultsDialogOpen, setIsCheckFileResultsDialogOpen] = useState(false);
  const [rejectedLoans, setRejectedLoans] = useState([]);
  const [nonexistentLoans, setNonexistentLoans] = useState([]);
  const [templateFilter, setTemplateFilter] = useState(null);

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const attachments = getUserAttachments(files);

  useEffect(() => {
    dataProvider
      .query('cm_profiles?page=1&items_per_page=30&is_pending=false', { method: 'GET' })
      .then(({ data }) => setCmProfilesList(data));
  }, [dataProvider]);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  const removeError = (...fields) => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => fields.includes(name) === false)));
  };

  const handleAttachFile = async e => {
    try {
      if (e.target.files[0]?.size > 10240000) {
        return notify('Maximum file size is 10 Mb', 'error');
      }
      setLoadingFile(true);
      const formData = new FormData();
      formData.append('file', e.target.files[0], 'file');
      const { data } = await dataProvider.query('files/users', { method: 'POST', body: formData });
      const fileCheck = await dataProvider.query(`discount_campaign/file/${data.id}`, { method: 'PATCH' });
      if (fileCheck.data.rejected.length || fileCheck.data.nonexistent.length) {
        setIsCheckFileResultsDialogOpen(true);
        fileCheck.data.rejected.length > 0 && setRejectedLoans(fileCheck.data.rejected);
        fileCheck.data.nonexistent.length > 0 && setNonexistentLoans(fileCheck.data.nonexistent);
      }
      setFiles(prevState => [...prevState, data]);
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    } finally {
      setLoadingFile(false);
      errors.file && removeError('file');
    }
  };
  const handleUnattachFile = id => setFiles(prevState => [...prevState.filter(i => i.id !== id)]);

  return (
    <FormWithRedirect
      save={(
        {
          name,
          campaign_type,
          valid_days_count,
          valid_to,
          discount_type,
          discount_total_amount,
          discount_total_percent,
          discount_principal_percent,
          discount_interest_percent,
          discount_commission_percent,
          discount_past_due_interest_percent,
          max_payments,
          audience_type,
          audience_id,
          is_enabled,
          show_to_client,
          send_notification,
          transmitter_id,
          template_id,
          channel_id,
          notify_from,
          notify_to,
        },
        ...rest
      ) => {
        const validationErrors = {};
        if (campaignType === 'regular' && daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (campaignType === 'regular' && daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }
        if (audience_type === 'file' && files.length === 0) {
          validationErrors.file = 'Upload a file!';
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        const payload = {
          name,
          campaign_type,
          discount_type,
          max_payments,
          audience_type,
          is_enabled,
          show_to_client,
          send_notification,
        };
        if (discount_type === 'amount') {
          payload.discount_total_amount = discount_total_amount;
        }
        if (discount_type === 'total_percent') {
          payload.discount_total_percent = discount_total_percent;
        }
        if (discount_type === 'compound_percent') {
          payload.discount_principal_percent = discount_principal_percent;
          payload.discount_interest_percent = discount_interest_percent;
          payload.discount_commission_percent = discount_commission_percent;
          payload.discount_past_due_interest_percent = discount_past_due_interest_percent;
        }
        if (audience_type === 'audience') {
          payload.audience_id = audience_id;
        }
        if (audience_type === 'file') {
          payload.file_id = files[0].id;
        }
        if (send_notification) {
          payload.transmitter_id = transmitter_id;
          payload.template_id = template_id;
          payload.notify_from = notify_from;
          payload.notify_to = notify_to;
          if (transmitter_id === 'cm') {
            payload.channel_id = channel_id;
          }
        }
        if (campaignType === 'onetime') {
          payload.valid_to = convertToISOString(valid_to, true);
        }
        if (campaignType === 'regular') {
          payload.valid_days_count = valid_days_count;
          payload.params = {
            week_days: daysMode === DAYS_MODE_DOW || daysMode === DAYS_MODE_ED ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
          };
        }
        save(...[payload, ...rest]);
      }}
      validate={formValidator}
      {...props}
      initialValues={{
        max_payments: 0,
        audience_type: campaignType === 'onetime' ? 1 : 'audience',
        campaign_type: campaignType,
        is_enabled: true,
        show_to_client: false,
        send_notification: false,
        valid_from: getToday(),
      }}
      render={formProps => {
        return (
          <form>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} sm={8}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom={false}>
                        {campaignType === 'onetime'
                          ? 'Create one-time discount campaign'
                          : 'Create regular discount campaign'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          source="name"
                          validate={[required('Please fill in obligatory field'), maxLength(255)]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="campaign_type"
                          label="Type of campaign"
                          choices={CAMPAIGN_TYPE_CHOICES}
                          validate={[required('Please fill in obligatory field')]}
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      {campaignType === 'regular' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              label="Offer valid from"
                              helperText={false}
                              source="valid_from"
                              validate={[required('Please fill in obligatory field')]}
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Offer valid till (shift in days from generation of offer)"
                              source="valid_days_count"
                              min={1}
                              max={100}
                              step={1}
                              fullWidth
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(1),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body1" color="textSecondary" gutterBottom={false}>
                              Periodicity
                            </Typography>
                            <RadioGroup row className={classes.mb2}>
                              <FormControlLabel
                                value={DAYS_MODE_DOW}
                                control={<Radio />}
                                label="Days of week"
                                checked={daysMode === DAYS_MODE_DOW}
                                onChange={() => {
                                  setMonthDays([]);
                                  setWeekDays([]);
                                  setDaysMode(DAYS_MODE_DOW);
                                  setErrors({});
                                }}
                              />
                              <FormControlLabel
                                value={DAYS_MODE_DOM}
                                control={<Radio />}
                                label="Days of month"
                                checked={daysMode === DAYS_MODE_DOM}
                                onChange={() => {
                                  setMonthDays([]);
                                  setWeekDays([]);
                                  setDaysMode(DAYS_MODE_DOM);
                                  setErrors({});
                                }}
                              />
                              <FormControlLabel
                                value={DAYS_MODE_ED}
                                control={<Radio />}
                                label="Every day"
                                checked={daysMode === DAYS_MODE_ED}
                                onChange={() => {
                                  setMonthDays([]);
                                  setWeekDays([1, 2, 3, 4, 5, 6, 7]);
                                  setDaysMode(DAYS_MODE_ED);
                                  setErrors({});
                                }}
                              />
                            </RadioGroup>

                            {daysMode === DAYS_MODE_DOW && (
                              <>
                                <div>
                                  {WEEK_DAYS.map((day, idx) => (
                                    <FormControlLabel
                                      key={day}
                                      control={
                                        <Checkbox
                                          onChange={() => onChangeWeekDay(idx + 1)}
                                          checked={weekDays.includes(idx + 1)}
                                        />
                                      }
                                      label={day}
                                    />
                                  ))}
                                </div>
                                <div>
                                  {errors.week_days && (
                                    <Typography color="error" variant="caption">
                                      {errors.week_days}
                                    </Typography>
                                  )}
                                </div>
                              </>
                            )}

                            {daysMode === DAYS_MODE_DOM && (
                              <>
                                <div className={classes.domList}>
                                  {MONTH_DAYS.map(({ value, label }) => (
                                    <div key={value} className={classes.domItem}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={() => onChangeMonthDay(value)}
                                            checked={monthDays.includes(value)}
                                          />
                                        }
                                        label={label}
                                      />
                                    </div>
                                  ))}
                                </div>
                                <div>
                                  {errors.month_days && (
                                    <Typography color="error" variant="caption">
                                      {errors.month_days}
                                    </Typography>
                                  )}
                                </div>
                              </>
                            )}
                          </Grid>
                        </>
                      )}
                      {campaignType === 'onetime' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              label="Offer valid from"
                              helperText="Format: DD.MM.YYYY"
                              source="valid_from"
                              validate={[
                                required('Please fill in obligatory field'),
                                value => isValidDateString(value),
                              ]}
                              fullWidth
                              disabled
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              label="Offer valid to"
                              helperText="Format: DD.MM.YYYY"
                              source="valid_to"
                              validate={[
                                required('Please fill in obligatory field'),
                                value => isValidDateString(value),
                              ]}
                              fullWidth
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="discount_type"
                          label="Type of discounts"
                          choices={DISCOUNT_TYPE_CHOICES}
                          validate={[required('Please fill in obligatory field')]}
                          fullWidth
                          onChange={e => {
                            setDiscountTypeSelected(e.target.value);
                            formProps.form.change('discount_total_amount', null);
                            formProps.form.change('discount_total_percent', null);
                            formProps.form.change('discount_principal_percent', null);
                            formProps.form.change('discount_interest_percent', null);
                            formProps.form.change('discount_commission_percent', null);
                            formProps.form.change('discount_past_due_interest_percent', null);
                          }}
                        />
                      </Grid>
                      {discountTypeSelected === 'amount' && (
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount PEN"
                            source="discount_total_amount"
                            fullWidth
                            min={1}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(1),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                      )}
                      {discountTypeSelected === 'total_percent' && (
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount %"
                            source="discount_total_percent"
                            min={1}
                            max={99}
                            step={1}
                            fullWidth
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(1),
                              maxValue(99),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                      )}
                      {discountTypeSelected === 'compound_percent' && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for Principal %"
                              source="discount_principal_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for Interest  %"
                              source="discount_interest_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for commission %"
                              source="discount_commission_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for Past due interest %"
                              source="discount_past_due_interest_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="max_payments"
                          label="Number of payments"
                          allowEmpty
                          emptyValue={0}
                          emptyText="Not selected"
                          optionText="name"
                          choices={PAYMENT_LIMIT_CHOICES}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      {campaignType === 'onetime' && (
                        <Grid item xs={12} sm={6}>
                          <SelectInput
                            source="audience_type"
                            label="Loans"
                            allowEmpty
                            emptyValue={1}
                            emptyText="Not selected"
                            choices={AUDIENCE_TYPE_CHOICES}
                            onChange={e => {
                              setAudienceTypeSelected(e.target.value);
                            }}
                            validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                            fullWidth
                          />
                        </Grid>
                      )}
                      {campaignType === 'regular' && (
                        <Grid item xs={12} sm={6}>
                          <SelectInput
                            source="audience_type"
                            label="Loans"
                            allowEmpty
                            emptyValue={1}
                            emptyText="Not selected"
                            choices={AUDIENCE_TYPE_CHOICES}
                            validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      )}
                      {((campaignType === 'onetime' && audienceTypeSelected === 'audience') ||
                        campaignType === 'regular') && (
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            resource="mass_sending_audiences"
                            label="Choose audience"
                            source="audience_id"
                            optionValueProp="id"
                            optionLabelProp="name"
                            required
                          />
                        </Grid>
                      )}
                      {campaignType === 'onetime' && audienceTypeSelected === 'file' && (
                        <Grid item xs={12} sm={6}>
                          <>
                            {files.length === 0 ? (
                              <Box className={classes.mt2}>
                                <label htmlFor="upload_file">
                                  <input
                                    className={classes.hidden}
                                    type="file"
                                    id="upload_file"
                                    accept="text/csv"
                                    onChange={handleAttachFile}
                                    onClick={e => {
                                      e.target.value = null;
                                    }}
                                  />
                                  <Button
                                    color="primary"
                                    aria-label="upload file"
                                    className={classes.uploadIcon}
                                    startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                                    component="span">
                                    Attach file
                                  </Button>
                                </label>
                              </Box>
                            ) : (
                              <Box className={classes.mt3}>
                                {attachments.map(file => (
                                  <Box key={file.src} className={classes.flex}>
                                    <Box className={classes.flex}>
                                      <AttachmentIcon color="primary" fontSize="small" />
                                      {file.file_name}
                                    </Box>
                                    <IconButton
                                      color="primary"
                                      aria-label="unattach file"
                                      className={classes.removeIcon}
                                      component="span"
                                      edge="start"
                                      onClick={() => handleUnattachFile(file.id)}>
                                      <CloseIcon color="primary" fontSize="small" />
                                    </IconButton>
                                  </Box>
                                ))}
                              </Box>
                            )}
                            <CheckFileResultsDialog
                              rejectedLoansList={rejectedLoans}
                              nonexistentLoansList={nonexistentLoans}
                              isOpened={isCheckFileResultsDialogOpen}
                              onApprove={() => {
                                setIsCheckFileResultsDialogOpen(false);
                                setRejectedLoans([]);
                                setNonexistentLoans([]);
                                setFiles([]);
                              }}
                              onReject={() => setIsCheckFileResultsDialogOpen(false)}
                            />
                          </>
                          <div>
                            {errors.file && (
                              <Typography color="error" variant="caption">
                                {errors.file}
                              </Typography>
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <BooleanInput label="Active" disabled helperText={false} source="is_enabled" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <BooleanInput label="Display in private cabinet" helperText={false} source="show_to_client" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <BooleanInput
                          label="Send notification"
                          helperText={false}
                          source="send_notification"
                          onChange={value => {
                            setSendNotification(value);
                            formProps.form.change('transmitter_id', null);
                            formProps.form.change('template_id', null);
                            formProps.form.change('channel_id', null);
                            formProps.form.change('notify_from', null);
                            formProps.form.change('notify_to', null);
                          }}
                        />
                      </Grid>
                    </Grid>
                    {sendNotification && (
                      <>
                        <Divider className={classes.my2} />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <SelectInput
                              onChange={e => {
                                const value = e.target.value;
                                formProps.form.change('template_id', null);
                                formProps.form.resetFieldState('template_id');
                                formProps.form.change('channel_id', null);
                                setIsCmSelected(value === 'cm');
                                if (value === 'cm') {
                                  setTemplateFilter(null);
                                } else if (value === 'mailer') {
                                  setTemplateFilter({ category: 'Email' });
                                } else {
                                  setTemplateFilter({ category: 'SMS' });
                                }
                              }}
                              source="transmitter_id"
                              optionText="name"
                              choices={TRANSMITTER}
                              validate={[required('Please fill in obligatory field')]}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          {isCmSelected ? (
                            <FormDataConsumer>
                              {() => (
                                <>
                                  <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                      resource="cm_templates"
                                      source="template_id"
                                      optionValueProp="template_id"
                                      optionLabelProp="name"
                                      filter={{ is_active: true, is_pending: false }}
                                      required
                                      requiredMessage="Please fill in obligatory field"
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <SelectInput
                                      helperText={false}
                                      source="channel_id"
                                      validate={[required('Please fill in obligatory field')]}
                                      choices={cmProfilesList}
                                      optionText="channel_id"
                                      optionValue="channel_id"
                                      fullWidth
                                    />
                                  </Grid>
                                </>
                              )}
                            </FormDataConsumer>
                          ) : (
                            <Grid item xs={12} sm={6}>
                              <Autocomplete
                                resource="templates"
                                source="template_id"
                                optionValueProp="id"
                                optionLabelProp="key"
                                filter={templateFilter}
                                required
                                requiredMessage="Please fill in obligatory field"
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              helperText="Format: hh:mm"
                              label="Sending time from"
                              source="notify_from"
                              validate={[
                                required('Please fill in obligatory field'),
                                value => shortTimeValidator(value),
                              ]}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextInput
                              helperText="Format: hh:mm"
                              label="Sending time till"
                              source="notify_to"
                              validate={[
                                required('Please fill in obligatory field'),
                                value => shortTimeValidator(value),
                              ]}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Box>
                  <Divider className={classes.my2} />
                  <SaveButton
                    label="Submit"
                    redirect="show"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Paper>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  campaignType: PropTypes.string,
};

const DiscountCampaignCreate = props => {
  const [campaignType, setCampaignType] = useState(null);
  const classes = useStyles();
  return (
    <>
      {!campaignType && (
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={8}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Select campaign type
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    onChange={e => {
                      setCampaignType(e.target.value);
                    }}
                    variant="filled"
                    fullWidth
                    value={campaignType}>
                    {CAMPAIGN_TYPE_CHOICES.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
      {campaignType && (
        <Create component="div" {...props}>
          <FormWrapper campaignType={campaignType} />
        </Create>
      )}
    </>
  );
};
export default DiscountCampaignCreate;
