import React from 'react';
import { PHONE_COUNTRY_CODE } from '../../../../constants';

const ImportProcessHelperBlackList = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/black_list.csv" download="import_example_black_list.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>User ID</b>, optional
        </li>
        <li>
          <b>CURP</b>, optional
        </li>
        <li>
          <b>First name</b>, optional
        </li>
        <li>
          <b>Last name</b>, optional
        </li>
        <li>
          <b>Phone number</b>, optional, format 5510203040, without <b>{PHONE_COUNTRY_CODE}</b>
        </li>
        <li>
          <b>E-mail</b>, optional
        </li>
        <li>
          <b>Contact phone number</b>, optional, format 5510203040, without <b>{PHONE_COUNTRY_CODE}</b>
        </li>
        <li>
          <b>Company phone number</b>, optional, format 5510203040, without <b>{PHONE_COUNTRY_CODE}</b>
        </li>
        <li>
          <b>Comment</b>, optional
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperBlackList;
