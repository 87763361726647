import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  NumberInput,
  useDataProvider,
  minValue,
  maxValue,
  number,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Autocomplete from '../../autocomplete/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';

import {
  TRANSMITTER,
  CAMPAIGN_TYPE_CHOICES,
  DISCOUNT_TYPE_CHOICES,
  PAYMENT_LIMIT_CHOICES,
  AUDIENCE_TYPE_CHOICES,
} from '../../../utils/dictionary';
import { integerValidator, isValidDateString, selectedValidator, shortTimeValidator } from '../../../utils/validator';
import { convertToISOString, convertToShortString } from '../../../utils/formatter';
import { DAYS_MODE_DOW, DAYS_MODE_DOM, WEEK_DAYS, MONTH_DAYS, DAYS_MODE_ED } from '../../../constants';
import { formValidator } from './validators';

const useStyles = makeStyles(theme => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const [daysMode, setDaysMode] = useState(DAYS_MODE_DOW);
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [isCmSelected, setIsCmSelected] = useState(false);
  const [discountTypeSelected, setDiscountTypeSelected] = useState('');
  const [audienceTypeSelected, setAudienceTypeSelected] = useState('');
  const [sendNotification, setSendNotification] = useState(false);
  const [errors, setErrors] = useState({});
  const [cmProfilesList, setCmProfilesList] = useState([]);
  const [templateFilter, setTemplateFilter] = useState(null);

  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    setDaysMode(
      Array.isArray(record.params?.month_days) && record.params.month_days.length > 0
        ? DAYS_MODE_DOM
        : record.params.week_days.length === 7
          ? DAYS_MODE_ED
          : DAYS_MODE_DOW,
    );
    setWeekDays(record.params?.week_days || []);
    setMonthDays(record.params?.month_days || []);
    setIsCmSelected(record.transmitter_id === 'cm');
    setDiscountTypeSelected(record.discount_type);
    setAudienceTypeSelected(record.audience_type);
    setSendNotification(record.send_notification);
  }, [record]);

  useEffect(() => {
    dataProvider
      .query('cm_profiles?page=1&items_per_page=30&is_pending=false', { method: 'GET' })
      .then(({ data }) => setCmProfilesList(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  return (
    <FormWithRedirect
      save={(
        {
          name,
          campaign_type,
          valid_days_count,
          valid_to,
          discount_type,
          discount_total_amount,
          discount_total_percent,
          discount_principal_percent,
          discount_interest_percent,
          discount_commission_percent,
          discount_past_due_interest_percent,
          max_payments,
          audience_type,
          audience_id,
          file_id,
          is_enabled,
          show_to_client,
          send_notification,
          transmitter_id,
          template_id,
          channel_id,
          notify_from,
          notify_to,
        },
        ...rest
      ) => {
        const validationErrors = {};
        if (record.campaign_type === 'regular' && daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (record.campaign_type === 'regular' && daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        const payload = {
          name,
          campaign_type,
          discount_type,
          max_payments,
          audience_type,
          is_enabled,
          show_to_client,
          send_notification,
        };
        if (discount_type === 'amount') {
          payload.discount_total_amount = discount_total_amount;
        }
        if (discount_type === 'total_percent') {
          payload.discount_total_percent = discount_total_percent;
        }
        if (discount_type === 'compound_percent') {
          payload.discount_principal_percent = discount_principal_percent;
          payload.discount_interest_percent = discount_interest_percent;
          payload.discount_commission_percent = discount_commission_percent;
          payload.discount_past_due_interest_percent = discount_past_due_interest_percent;
        }
        if (audience_type === 'audience') {
          payload.audience_id = audience_id;
        }
        if (audience_type === 'file') {
          payload.file_id = file_id;
        }
        if (send_notification) {
          payload.transmitter_id = transmitter_id;
          payload.template_id = template_id;
          payload.notify_from = notify_from;
          payload.notify_to = notify_to;
          if (transmitter_id === 'cm') {
            payload.channel_id = channel_id;
          }
        }
        if (record.campaign_type === 'onetime') {
          payload.valid_to = convertToISOString(valid_to, true);
        }
        if (record.campaign_type === 'regular') {
          payload.valid_days_count = valid_days_count;
          payload.params = {
            week_days: daysMode === DAYS_MODE_DOW || daysMode === DAYS_MODE_ED ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
          };
        }
        save(...[payload, ...rest]);
      }}
      validate={formValidator}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={8}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom={false}>
                      {record.campaign_type === 'onetime'
                        ? 'Update one-time discount campaign'
                        : 'Update regular discount campaign'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source="name"
                        validate={[required('Please fill in obligatory field'), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="campaign_type"
                        label="Type of campaign"
                        choices={CAMPAIGN_TYPE_CHOICES}
                        validate={[required('Please fill in obligatory field')]}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    {record.campaign_type === 'regular' && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            label="Offer valid from"
                            helperText={false}
                            source="valid_from"
                            format={v => convertToShortString(v)}
                            validate={[required('Please fill in obligatory field')]}
                            fullWidth
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Offer valid till (shift in days from generation of offer)"
                            source="valid_days_count"
                            min={1}
                            max={100}
                            step={1}
                            fullWidth
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(1),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1" color="textSecondary" gutterBottom={false}>
                            Periodicity
                          </Typography>
                          <RadioGroup row className={classes.mb2}>
                            <FormControlLabel
                              value={DAYS_MODE_DOW}
                              control={<Radio />}
                              label="Days of week"
                              checked={daysMode === DAYS_MODE_DOW}
                              onChange={() => {
                                setMonthDays([]);
                                setWeekDays([]);
                                setDaysMode(DAYS_MODE_DOW);
                                setErrors({});
                              }}
                            />
                            <FormControlLabel
                              value={DAYS_MODE_DOM}
                              control={<Radio />}
                              label="Days of month"
                              checked={daysMode === DAYS_MODE_DOM}
                              onChange={() => {
                                setMonthDays([]);
                                setWeekDays([]);
                                setDaysMode(DAYS_MODE_DOM);
                                setErrors({});
                              }}
                            />
                            <FormControlLabel
                              value={DAYS_MODE_ED}
                              control={<Radio />}
                              label="Every day"
                              checked={daysMode === DAYS_MODE_ED}
                              onChange={() => {
                                setMonthDays([]);
                                setWeekDays([1, 2, 3, 4, 5, 6, 7]);
                                setDaysMode(DAYS_MODE_ED);
                                setErrors({});
                              }}
                            />
                          </RadioGroup>

                          {daysMode === DAYS_MODE_DOW && (
                            <>
                              <div>
                                {WEEK_DAYS.map((day, idx) => (
                                  <FormControlLabel
                                    key={day}
                                    control={
                                      <Checkbox
                                        onChange={() => onChangeWeekDay(idx + 1)}
                                        checked={weekDays.includes(idx + 1)}
                                      />
                                    }
                                    label={day}
                                  />
                                ))}
                              </div>
                              <div>
                                {errors.week_days && (
                                  <Typography color="error" variant="caption">
                                    {errors.week_days}
                                  </Typography>
                                )}
                              </div>
                            </>
                          )}

                          {daysMode === DAYS_MODE_DOM && (
                            <>
                              <div className={classes.domList}>
                                {MONTH_DAYS.map(({ value, label }) => (
                                  <div key={value} className={classes.domItem}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={() => onChangeMonthDay(value)}
                                          checked={monthDays.includes(value)}
                                        />
                                      }
                                      label={label}
                                    />
                                  </div>
                                ))}
                              </div>
                              <div>
                                {errors.month_days && (
                                  <Typography color="error" variant="caption">
                                    {errors.month_days}
                                  </Typography>
                                )}
                              </div>
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                    {record.campaign_type === 'onetime' && (
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          label="Offer valid to"
                          helperText="Format: DD.MM.YYYY"
                          source="scheduled_till"
                          validate={[required('Please fill in obligatory field'), value => isValidDateString(value)]}
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="discount_type"
                        label="Type of discounts"
                        choices={DISCOUNT_TYPE_CHOICES}
                        validate={[required('Please fill in obligatory field')]}
                        fullWidth
                        onChange={e => {
                          setDiscountTypeSelected(e.target.value);
                          formProps.form.change('discount_total_amount', null);
                          formProps.form.change('discount_total_percent', null);
                          formProps.form.change('discount_principal_percent', null);
                          formProps.form.change('discount_interest_percent', null);
                          formProps.form.change('discount_commission_percent', null);
                          formProps.form.change('discount_past_due_interest_percent', null);
                        }}
                      />
                    </Grid>
                    {discountTypeSelected === 'amount' && (
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          label="Discount PEN"
                          source="discount_total_amount"
                          fullWidth
                          min={1}
                          step={1}
                          validate={[
                            required('Please fill in obligatory field'),
                            minValue(1),
                            integerValidator('Incorrect discount'),
                            number('Incorrect discount'),
                          ]}
                        />
                      </Grid>
                    )}
                    {discountTypeSelected === 'total_percent' && (
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          label="Discount %"
                          source="discount_total_percent"
                          min={1}
                          max={99}
                          step={1}
                          fullWidth
                          validate={[
                            required('Please fill in obligatory field'),
                            minValue(1),
                            maxValue(99),
                            integerValidator('Incorrect discount'),
                            number('Incorrect discount'),
                          ]}
                        />
                      </Grid>
                    )}
                    {discountTypeSelected === 'compound_percent' && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for Principal %"
                            source="discount_principal_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for Interest  %"
                            source="discount_interest_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for commission %"
                            source="discount_commission_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for Past due interest %"
                            source="discount_past_due_interest_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="max_payments"
                        label="Number of payments"
                        allowEmpty
                        emptyValue={0}
                        emptyText="Not selected"
                        optionText="name"
                        choices={PAYMENT_LIMIT_CHOICES}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    {record.campaign_type === 'onetime' && (
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="audience_type"
                          label="Loans"
                          allowEmpty
                          emptyValue={1}
                          emptyText="Not selected"
                          choices={AUDIENCE_TYPE_CHOICES}
                          onChange={e => {
                            setAudienceTypeSelected(e.target.value);
                          }}
                          validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                          fullWidth
                        />
                      </Grid>
                    )}
                    {record.campaign_type === 'regular' && (
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="audience_type"
                          label="Loans"
                          allowEmpty
                          emptyValue={1}
                          emptyText="Not selected"
                          choices={AUDIENCE_TYPE_CHOICES}
                          validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                          disabled
                          fullWidth
                        />
                      </Grid>
                    )}
                    {((record.campaign_type === 'onetime' && audienceTypeSelected === 'audience') ||
                      record.campaign_type === 'regular') && (
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          resource="mass_sending_audiences"
                          label="Choose audience"
                          source="audience_id"
                          optionValueProp="id"
                          optionLabelProp="name"
                          required
                        />
                      </Grid>
                    )}
                    {record.campaign_type === 'onetime' && audienceTypeSelected === 'file' && (
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body2" gutterBottom={false}>
                          Here should be file upload
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <BooleanInput label="Active" disabled helperText={false} source="is_enabled" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <BooleanInput label="Display in private cabinet" helperText={false} source="show_to_client" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <BooleanInput
                        label="Send notification"
                        helperText={false}
                        source="send_notification"
                        onChange={value => {
                          setSendNotification(value);
                          formProps.form.change('transmitter_id', null);
                          formProps.form.change('template_id', null);
                          formProps.form.change('channel_id', null);
                          formProps.form.change('notify_from', null);
                          formProps.form.change('notify_to', null);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {sendNotification && (
                    <>
                      <Divider className={classes.my2} />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <SelectInput
                            onChange={e => {
                              const value = e.target.value;
                              formProps.form.change('template_id', null);
                              formProps.form.resetFieldState('template_id');
                              formProps.form.change('channel_id', null);
                              setIsCmSelected(value === 'cm');
                              if (value === 'cm') {
                                setTemplateFilter(null);
                              } else if (value === 'mailer') {
                                setTemplateFilter({ category: 'Email' });
                              } else {
                                setTemplateFilter({ category: 'SMS' });
                              }
                            }}
                            source="transmitter_id"
                            optionText="name"
                            choices={TRANSMITTER}
                            validate={[required('Please fill in obligatory field')]}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        {isCmSelected ? (
                          <FormDataConsumer>
                            {() => (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <Autocomplete
                                    resource="cm_templates"
                                    source="template_id"
                                    optionValueProp="template_id"
                                    optionLabelProp="name"
                                    filter={{ is_active: true, is_pending: false }}
                                    required
                                    requiredMessage="Please fill in obligatory field"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <SelectInput
                                    helperText={false}
                                    source="channel_id"
                                    validate={[required('Please fill in obligatory field')]}
                                    choices={cmProfilesList}
                                    optionText="channel_id"
                                    optionValue="channel_id"
                                    fullWidth
                                  />
                                </Grid>
                              </>
                            )}
                          </FormDataConsumer>
                        ) : (
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              resource="templates"
                              source="template_id"
                              optionValueProp="id"
                              optionLabelProp="key"
                              filter={templateFilter}
                              required
                              requiredMessage="Please fill in obligatory field"
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            helperText="Format: hh:mm"
                            label="Sending time from"
                            source="notify_from"
                            validate={[required('Please fill in obligatory field'), value => shortTimeValidator(value)]}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextInput
                            helperText="Format: hh:mm"
                            label="Sending time till"
                            source="notify_to"
                            validate={[required('Please fill in obligatory field'), value => shortTimeValidator(value)]}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
                <Divider className={classes.my2} />
                <SaveButton
                  label="Save"
                  redirect="list"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    campaign_type: PropTypes.string,
    discount_type: PropTypes.string,
    max_payments: PropTypes.number,
    valid_from: PropTypes.string,
    valid_to: PropTypes.string,
    is_enabled: PropTypes.bool,
    send_notification: PropTypes.bool,
    show_to_client: PropTypes.bool,
    audience_type: PropTypes.string,
    params: PropTypes.shape({
      week_days: PropTypes.array,
      month_days: PropTypes.array,
    }),
    transmitter_id: PropTypes.number,
  }),
};

const DiscountCampaignEdit = props => (
  <Edit component="div" actions={false} mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default DiscountCampaignEdit;
