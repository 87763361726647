import React, { useState, memo } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Menu } from '@material-ui/core';
import DateFnsAdapter from '@date-io/date-fns';
import PropTypes from 'prop-types';

const CopyUserMemoized = memo(function CopyUserInfoButton({ record, onCopy }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleTogleMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const { id, first_name, father_surname, mother_surname, created_at } = record;

  const dateFns = new DateFnsAdapter();

  const userLink = window.location.href;

  const devCopyMsg = `{
    id: ${id}
    name: ${first_name}
    father_surname: ${father_surname} 
    mother_surname: ${mother_surname},
    created_at: ${dateFns.format(new Date(created_at), 'yyyy-MM-dd')},
    user_link: ${userLink}
  }`;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    { title: 'Copy user link', onClick: () => onCopy(userLink) },
    { title: 'Copy user data (dev)', onClick: () => onCopy(devCopyMsg) },
  ];

  return (
    <>
      <Button variant="contained" color="primary" endIcon={<ArrowDropDownIcon />} onClick={handleTogleMenu}>
        Copy
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ style: { marginTop: '45px' } }}>
        {options.map(option => (
          <MenuItem
            key={option.title}
            onClick={() => {
              option.onClick();
              handleClose();
            }}>
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

CopyUserMemoized.propTypes = {
  record: PropTypes.object,
  onCopy: PropTypes.func,
};

export default CopyUserMemoized;
