import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { formatDatetime, formatCurrency } from '../../../utils/formatter';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    maxWidth: '300px',
  },
}));

const DiscountOfferTable = ({ loanId, refreshedAt, onRefresh }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const [discountOffers, setDiscountOffers] = useState(null);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [cancellableOffer, setCancellableOffer] = useState({});
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    setLoading(true);
    dataProvider
      .getList('discount_offers', {
        filter: { 'loan.id': loanId },
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setDiscountOffers(data);
        setTotal(total);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, loanId, refreshedAt, page, perPage]);

  const handleSubmitCancel = (_e, discountOffer) => {
    setIsDialogOpened(false);
    const offer = { ...discountOffer, state: 'cancelled' };

    dataProvider
      .query(`discount_offers/${discountOffer.id}`, {
        method: 'PATCH',
        body: JSON.stringify(offer),
      })
      .then(() => notify('The offer has been successfully canceled', 'success'))
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => {
        onRefresh();
        setCancellableOffer({});
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(discountOffers)) {
    return null;
  }

  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Discount campaign ID</TableCell>
            <TableCell>Created by</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Category of discount</TableCell>
            <TableCell>Start at</TableCell>
            <TableCell>Amount to pay</TableCell>
            <TableCell>Number of payments</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>End at</TableCell>
            <TableCell>Total paid</TableCell>
            <TableCell>Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {discountOffers.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.discount_campaign_id}</TableCell>
              <TableCell>{row.created_by_name}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.start_at && formatDatetime(row.start_at)}</TableCell>
              <TableCell>{formatCurrency(row.amount_to_pay)}</TableCell>
              <TableCell>{row.max_payments}</TableCell>
              <TableCell>{formatCurrency(row.discount)}</TableCell>
              <TableCell>{row.end_at && formatDatetime(row.end_at)}</TableCell>
              <TableCell>{formatCurrency(row.total_paid)}</TableCell>
              <TableCell>{row.state}</TableCell>
              <TableCell align="right">
                <ButtonGroup
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={permissions.indexOf('CAN_NOTIFICATION_EDIT') === -1}>
                  <Button
                    variant="contained"
                    size="small"
                    className={classes.m2}
                    onClick={() => {
                      setCancellableOffer(row);
                      setIsDialogOpened(true);
                    }}
                    color="primary"
                    disabled={['cancelled', 'expired', 'paid'].includes(row.state)}>
                    Cancel
                  </Button>
                </ButtonGroup>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      <Dialog
        open={isDialogOpened}
        onClose={() => {
          setIsDialogOpened(false);
          setCancellableOffer({});
        }}>
        <DialogContent>
          <DialogContentText>Do you want to cancel discount?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={event => handleSubmitCancel(event, cancellableOffer)} color="primary">
            Yes
          </Button>
          <Button
            onClick={() => {
              setIsDialogOpened(false);
              setCancellableOffer({});
            }}
            color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DiscountOfferTable.propTypes = {
  loanId: PropTypes.number,
  refreshedAt: PropTypes.number,
  onRefresh: PropTypes.func,
};

export default DiscountOfferTable;
