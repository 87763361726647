import React, { cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  FunctionField,
  Filter,
  NumberInput,
  SelectInput,
  TextInput,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
  useDataProvider,
  useRefresh,
  useNotify,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import Pagination from '../../Pagination';
import ApplicationColorLegend from '../../application-color-legend/ApplicationColorLegend';
import AsyncSelectInput from '../../input/AsyncSelectInput';
import DateFilterInput from '../../filter/DateFilterInput';
import QuickFilterInput from '../../filter/QuickFilterInput';
import { formatDatetime } from '../../../utils/formatter';
import { AVENTUS_DECISION_ENGINE_STATUSES as ade_statuses } from '../../../utils/dictionary';
import { getUserId } from '../../../utils/authentication';
import { getRequestDuration } from '../../../utils/getRequestDuration';
import { timezone } from '../../../utils/adapter';
import { marginZeroStyles, applicationStatusChoices } from '../../../constants';

import { verifierRow } from './utils';
import ApplicationsBulkActionButtons from './components/ApplicationsBulkActionButtons';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{
          resource: 'affiliates',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
    </Filter>
  );
};

export const ListRepeatFilter = props => (
  <Filter {...props}>
    <NumberInput label="Id" source="id" alwaysOn />
    <NumberInput label="User Id" source="user->id" />
    <TextInput label="Document number" source="user->userDocuments->number" />
    <DateFilterInput label="Created before" source="created_at|before" before />
    <DateFilterInput label="Created after" source="created_at|after" after />
    <AsyncSelectInput
      label="Affiliate"
      source="affiliate"
      query={{
        resource: 'affiliates',
        payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
      }}
      mapper={({ id, name }) => ({ value: id, name })}
    />
    <TextInput label="Verifier" source="verifier->username" />
    <SelectInput label="Status" source="state" choices={applicationStatusChoices} />
  </Filter>
);

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ApplicationColorLegend />
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const ManualVerificationList = ({ filter, listfilters: CustomFilters, permissions, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isTransitionInProgress, setIsTransitionInProgress] = useState(false);
  const userId = getUserId();

  const isRowSelectable = record =>
    record.state === 'pending' ||
    (record.state === 'processing' && ['manual', null].includes(record.decision_engine_id));

  const loanRowStyle = record => ({
    backgroundColor: getRequestDuration(record),
  });

  const assignVerifier = applicationId => {
    setIsTransitionInProgress(true);
    dataProvider
      .query(`applications/${applicationId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name: 'assign_verifier', params: { verifier_id: userId } }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'))
      .finally(() => setIsTransitionInProgress(false));
  };

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={CustomFilters ? <CustomFilters /> : <ListFilter />}
      actions={<ListActions />}
      filter={{ ...filter, manual_verification: userId }}
      permissions={permissions}
      {...props}>
      <Datagrid
        rowStyle={loanRowStyle}
        rowClick={(id, path, { verifier_id }) => (verifier_id ? 'show' : notify('Verifier not assigned.'))}
        isRowSelectable={isRowSelectable}>
        <TextField source="id" />
        <FunctionField
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => {
            return (
              <Link
                onClick={e => e.stopPropagation()}
                href={'#users/' + user_id}>{`${user_first_name} ${user_last_name} #${user_id}`}</Link>
            );
          }}
        />
        ,
        <ChipField size="small" source="product_name" />
        <NumberField source="requested_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="max_approved_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="requested_tenor" />
        <ChipField size="small" source="state" />
        <FunctionField
          render={({ decision_engine_id, state, verifier_username }) =>
            verifierRow(decision_engine_id, state, verifier_username)
          }
          label="Verifier"
        />
        <FunctionField label="Request type" render={({ is_repeat }) => (is_repeat ? 'Repeat' : 'First')} />
        <NumberField label="Request number" source="request_number" />
        <NumberField label="Loan number" source="potential_loan_number" />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Affiliate"
          source="affiliate"
          render={(record, key) => (record[key] ? <Chip size="small" label={record[key]} /> : null)}
        />
        <FunctionField
          label="ADE status"
          source="aventus_decision_engine_status"
          render={(record, key) =>
            record[key] ? (
              <Chip size="small" label={(ade_statuses.find(item => item.id === record[key]) || { name: null }).name} />
            ) : null
          }
        />
        <FunctionField
          label="Actions"
          render={({ id, verifier_id }) => (
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={
                permissions.indexOf('CAN_APPLICATION_TRANSITION_ASSIGN_VERIFIER') === -1 ||
                !!verifier_id ||
                isTransitionInProgress
              }
              onClick={e => e.stopPropagation() || assignVerifier(id)}>
              Assign
            </Button>
          )}
        />
        <FunctionField
          label="Verification"
          render={record => (
            <Link
              href={`#applications_verification/${record.id}/show`}
              underline="none"
              target="_blank"
              rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

ManualVerificationList.propTypes = {
  filter: PropTypes.object,
  permissions: PropTypes.arrayOf(PropTypes.string),
  listfilters: PropTypes.func,
};

const ManualVerificationAllFilters = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="Contract number" source="contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <TextInput label="Verifier" source="verifier->username" />
      <SelectInput label="Status" source="state" choices={applicationStatusChoices} />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Assigned before" source="verifier_assigned_at|before" before />
      <DateFilterInput label="Assigned after" source="verifier_assigned_at|after" after />
      <SelectInput
        label="Request type"
        source="is_repeat"
        choices={[
          { id: 'false', name: 'First' },
          { id: 'true', name: 'Repeated' },
        ]}
      />
      <AsyncSelectInput
        label="Affiliate"
        source="affiliate"
        query={{
          resource: 'affiliates',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <QuickFilterInput
        source="postponed_until|after"
        label="Postponed all"
        defaultValue={timezone.shift(new Date())}
      />
      <QuickFilterInput
        source="postponed_until|before"
        label="Postponed in work"
        defaultValue={timezone.shift(new Date())}
      />
    </Filter>
  );
};

export const ManualVerificationAll = ({ filter, permissions, ...props }) => {
  const isRowSelectable = record =>
    record.state === 'pending' ||
    (record.state === 'processing' && ['manual', null].includes(record.decision_engine_id));

  const loanRowStyle = record => ({
    backgroundColor: getRequestDuration(record),
  });

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={<ApplicationsBulkActionButtons />}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ManualVerificationAllFilters />}
      actions={<ListActions />}
      filter={{ ...filter }}
      permissions={permissions}
      {...props}>
      <Datagrid rowStyle={loanRowStyle} rowClick="show" isRowSelectable={isRowSelectable}>
        <TextField source="id" />
        <FunctionField
          label="Verifier"
          source="verifier_id"
          render={({ verifier_id, verifier_username = '' }) => {
            return `${verifier_username} #${verifier_id}`;
          }}
        />
        <ChipField size="small" source="state" />
        <NumberField source="requested_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="max_approved_principal" options={{ style: 'currency', currency: 'PEN' }} />
        <NumberField source="requested_tenor" />
        <FunctionField
          label="Created at"
          source="created_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Assigned at"
          source="verifier_assigned_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Resolved at"
          source="resolved_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
        />
        <FunctionField
          label="Processing time"
          render={({ resolved_at, verifier_assigned_at }) => {
            if (verifier_assigned_at && resolved_at) {
              const diff = new Date(resolved_at) - new Date(verifier_assigned_at);
              const minutes = Math.floor((diff / (1000 * 60)) % 60);
              const seconds = Math.floor((diff / 1000) % 60);
              return `${minutes}:${seconds}`;
            } else {
              return null;
            }
          }}
        />
        <FunctionField
          label="New tab"
          render={record => (
            <Link href={`#applications/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
        <FunctionField
          label="Verification"
          render={record => (
            <Link
              href={`#applications_verification/${record.id}/show`}
              underline="none"
              target="_blank"
              rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

ManualVerificationAll.propTypes = {
  filter: PropTypes.object,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

export default ManualVerificationList;
