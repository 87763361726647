import React, { useState, useEffect } from 'react';
import { usePermissions, useDataProvider, useNotify } from 'react-admin';
import { Card, CardHeader, CardContent, Tabs, Tab } from '@material-ui/core';
import TabPanel from './TabPanel';
import AssignedActionsReport from './AssignedActionsReport';
import CollectionAlertsReport from './CollectionAlertsReport';

export default () => {
  const [activeTab, setActiveTab] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [collectionGroups, setCollectionGroups] = useState([]);

  const { permissions = [] } = usePermissions();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const user = JSON.parse(localStorage.getItem('admin_fields'));
  const isHeadOfCollection = user?.roles?.includes('ROLE_HEAD_OF_COLLECTION');

  const handleTabChange = (_event, newValue) => setActiveTab(newValue);

  useEffect(() => {
    if (!isHeadOfCollection) return;
    dataProvider
      .getList('admins', {
        filter: {
          role_permission: 'CAN_BE_COLLECTION_SPECIALIST',
          is_blocked: false,
          pagination: false,
        },
        pagination: {},
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));

    dataProvider
      .getList('collection_groups', {
        filter: {},
        pagination: {},
        sort: {},
      })
      .then(({ data }) => setCollectionGroups(data))
      .catch(error => notify(`Error: ${error.message}`, 'error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader title="Welcome to the administration dashboard" />
      {permissions.includes('CAN_BE_COLLECTION_SPECIALIST') && (
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Assigned/Actions" />
            <Tab label="Collection alerts" />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <AssignedActionsReport collectionGroups={collectionGroups} admins={admins} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <CollectionAlertsReport collectionGroups={collectionGroups} admins={admins} />
          </TabPanel>
        </CardContent>
      )}
    </Card>
  );
};
