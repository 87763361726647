import { PHONE_COUNTRY_CODE } from '../constants';
import getAdminTimezoneOffset from './getAdminTimezoneOffset';

export const phoneNumber = {
  placeholder: `${PHONE_COUNTRY_CODE} xxx xxx xxx`,
  parser: value => value && value.replace(/\D/g, '').slice(2, 11),
  formatter: (value, masked = false) => {
    if (!value) {
      return PHONE_COUNTRY_CODE;
    }

    if (value.length >= 7) {
      return `${PHONE_COUNTRY_CODE} ${value.slice(0, 3)} ${masked ? '***' : value.slice(3, 6)} ${value.slice(6)}`;
    } else if (value.length >= 4) {
      return `${PHONE_COUNTRY_CODE} ${value.slice(0, 3)} ${value.slice(3)}`;
    } else {
      return `${PHONE_COUNTRY_CODE} ${value}`;
    }
  },
  validator: value => value && value.replace(/\D/g, '').length === 9,
  raValidator:
    (message = 'Invalid phone number') =>
    value =>
      value ? (value.replace(/\D/g, '').length === 9 ? undefined : message) : undefined,
};

const getTimezoneShiftMilliseconds = () => 60000 * (getAdminTimezoneOffset() + new Date().getTimezoneOffset());

export const timezoneOffsetLabel = offset => {
  const sign = offset < 0 ? '-' : '+';
  const minuteOffset = Math.abs(offset) % 60;
  const minuteOffsetLabel = minuteOffset < 10 ? `0${minuteOffset}` : `${minuteOffset}`;
  const hoursOffset = Math.floor(Math.abs(offset / 60));
  const hoursOffsetLabel = hoursOffset < 10 ? `0${hoursOffset}` : `${hoursOffset}`;

  return `${sign}${hoursOffsetLabel}:${minuteOffsetLabel}`;
};

export const timezone = {
  shift: date => new Date(date.valueOf() + getTimezoneShiftMilliseconds()),
  unshift: date => new Date(date.valueOf() - getTimezoneShiftMilliseconds()),
  setTime: (date, h = 0, m = 0, s = 0, ms = 0) => {
    const v = new Date(date);
    v.setHours(0, 0, 0, 0);
    return new Date(v.valueOf() - getTimezoneShiftMilliseconds() + ((h * 60 + m) * 60 + s) * 1000 + ms);
  },
  setTimeWithoutOffset: (date, h = 0, m = 0, s = 0, ms = 0) => {
    const v = new Date(date);
    v.setHours(0, 0, 0, 0);
    return new Date(v.valueOf() + ((h * 60 + m) * 60 + s) * 1000 + ms);
  },
};
