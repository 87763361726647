import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(() => ({
  emptyValue: {
    color: '#c7c7c7',
  },
  arrowIcon: {
    verticalAlign: 'bottom',
    cursor: 'pointer',
  },
  userTypography: {
    display: 'inline-block',
  },
  fakeMenuItem: {
    padding: '0 !important',
  },
}));

const fakeMenuItemClasses =
  'MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters  MuiListItem-button';

const FakeMenuItemWrapper = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <li className={[classes.fakeMenuItem, fakeMenuItemClasses].join(' ')} {...rest}>
      {children}
    </li>
  );
};

FakeMenuItemWrapper.propTypes = {
  children: PropTypes.node,
};

const UserReadonlyField = ({ value, actions = [], label, valueText, noValueMsg, isLast = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleTogleMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasActions = !!actions.length;

  if (!value)
    return (
      <span className={classes.emptyValue} style={{ marginRight: '6px' }}>
        {noValueMsg} is not defined
      </span>
    );

  return (
    <>
      <Box className={classes.userTypography} style={{ marginRight: '6px' }}>
        {label && (
          <Typography variant="body1" className={classes.userTypography} style={{ marginRight: '6px' }}>
            {label}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          className={classes.userTypography}
          style={{ fontWeight: hasActions ? 600 : 400 }}>
          {valueText}
        </Typography>
        {hasActions && <KeyboardArrowDownIcon onClick={handleTogleMenu} className={classes.arrowIcon} />}
        {!isLast ? ',' : '.'}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ style: { marginTop: '30px' } }}>
        {actions.map((action, index) => {
          if (React.isValidElement(action)) {
            return <FakeMenuItemWrapper key={index}>{action}</FakeMenuItemWrapper>;
          }
          return (
            <MenuItem
              key={index}
              disabled={action.disabled}
              onClick={() => {
                action.onClick();
                handleClose();
              }}>
              {action.title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

UserReadonlyField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onClickCopy: PropTypes.func,
  children: PropTypes.node,
  noValueMsg: PropTypes.string,
  actions: PropTypes.array,
  label: PropTypes.string,
  valueText: PropTypes.string,
  isLast: PropTypes.bool,
};

export default UserReadonlyField;
